<div class="content">
  <app-map></app-map>
  <app-legend [header]="'Erreichbar in'" [items]="reachability"></app-legend>
  <a href="https://triply.at"><img src="assets/triply_logo.png" class="logo"/></a>
  <div class="info" [ngClass]="hideInfo ? 'hidden' : ''">
    <a class="close" (click)="clickEvent()">&#x2715;</a>
    <h1>Wie Sie diese Karte benutzen können</h1>
    <p>
      Die Karte stellt die Einzugsbereiche, aus denen eine Teststation in 5, 10 oder 15 Minuten mit dem Rad erreicht
      werden kann, dar.
    </p>
    <p>
      Durch das Klicken einer Teststation wird der Einzugsbereich der Teststation dargestellt. Weitere Infos zu dem
      Testablauf finden Sie auf <a href="https://www.linz.at/massentest.php">linz.at</a>. Mehr Infos zu triply finden Sie
      unter <a href="https://triply.at">triply.at</a>
    </p>

  </div>
</div>
