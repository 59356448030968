import {Component} from '@angular/core';
import {LegendItem} from './models/LegendItem';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  hideInfo = false;
  reachability = [
    new LegendItem('#38b2a388', '5 Minuten'),
    new LegendItem('#67dba588', '10 Minuten'),
    new LegendItem('#b0f2bc88', '15 Minuten')
  ];

  clickEvent() {
    this.hideInfo = true;
  }
}
