import {SafeStyle} from '@angular/platform-browser';

export class LegendItem {
  private _color: string;
  private _description: string;


  constructor(color: string, description: string) {
    this._color = color;
    this._description = description;
  }

  get color(): string {
    return this._color;
  }

  get description(): string {
    return this._description;
  }
}
