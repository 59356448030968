import {Component, Input, OnInit} from '@angular/core';
import {LegendItem} from '../../models/LegendItem';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.css']
})
export class LegendComponent implements OnInit {

  @Input() items: Array<LegendItem>;
  @Input() header: string;

  constructor(public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
  }

}
