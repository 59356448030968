import {Injectable} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import {environment} from '../../environments/environment';
import TestLocation from '../models/TestLocation';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private map: mapboxgl.Map;
  settings = {
    style: 'mapbox://styles/mapbox/light-v10',
    LatLng: [48.30639, 14.28611],
    zoom: 11.5,
    colors: {
      900: '#b0f2bc',
      600: '#67dba5',
      360: '#38b2a3',
    }
  };
  data: any = [];
  locations: TestLocation[] = [];

  constructor() {
    mapboxgl.accessToken = environment.mapbox.accessToken;
    this.locations = [
      {
        name: 'BRG solarCity Linz',
        street: 'Heliosallee 140-142',
        zipcode: 4030,
        city: 'Linz'
      },
      {
        name: 'Design Center Linz',
        street: 'Europaplatz 1',
        zipcode: 4020,
        city: 'Linz'
      },
      {
        name: 'Dr. Ernst Koref Schule',
        street: 'Uhlandgasse 5',
        zipcode: 4020,
        city: 'Linz'
      },
      {
        name: 'Dr. Karl Renner Schule',
        street: 'Flötzerweg 61',
        zipcode: 4030,
        city: 'Linz'
      },
      {
        name: 'Ferdinand-Hüttner-Schule',
        street: 'Wieningerstraße 15',
        zipcode: 4020,
        city: 'Linz'
      },
      {
        name: 'Sport Mittelschule-Linz-Kleinmünchen',
        street: 'Meindlstraße 25',
        zipcode: 4030,
        city: 'Linz'
      },
      {
        name: 'Tabakfabrik Linz',
        street: 'Peter-Behrens-Platz 5',
        zipcode: 4020,
        city: 'Linz'
      },
      {
        name: 'Tips Arena',
        street: 'Ziegeleistraße 76',
        zipcode: 4020,
        city: 'Linz'
      },
      {
        name: 'Volkshaus Bindermichl',
        street: 'Uhlandgasse 5',
        zipcode: 4020,
        city: 'Linz'
      },
      {
        name: 'Volkshaus Dornach-Auhof',
        street: 'Niedermayrweg 7',
        zipcode: 4040,
        city: 'Linz'
      },
      {
        name: 'Volkshaus Harbach',
        street: 'Im Bachlfeld 31',
        zipcode: 4040,
        city: 'Linz'
      }
    ].map((el, idx) => {
      (el as any).id = idx;
      return el as unknown as TestLocation;
    });
  }

  buildMap() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.settings.style,
      zoom: this.settings.zoom,
      center: [this.settings.LatLng[1], this.settings.LatLng[0]],
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('load', () => {
      this.setupDataSources();
    });

    this.map.on('click', 'testing-locations', event => {
      this.map.getCanvas().style.cursor = 'pointer';

      const coordinates = event.features[0].geometry.coordinates.slice();
      const props = event.features[0].properties;
      const description = `<strong>Teststation ${props.name}</strong><p>${props.street}, ${props.zipcode} ${props.city}</p>`;
      while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: true
      }).setLngLat(coordinates).setHTML(description).addTo(this.map);

      if (!event.features) {
        return;
      }
      const selected = event.features[0];
      if (!selected) {
        return;
      }
      this.locations.forEach(l => this.map.setLayoutProperty(`coverage-${l.id}`, 'visibility', 'none'));
      this.map.setLayoutProperty(`coverage-${selected.properties.index}`, 'visibility', 'visible');
    });
  }

  private setupDataSources() {
    this.map.loadImage('assets/location.png', (err, image) => {
      if (err) {
        throw err;
      }
      this.map.addImage('location-marker', image);
      this.map.addSource('testing-locations', {
        type: 'geojson',
        data: 'assets/testing-locations.geojson'
      });
      this.map.addLayer({
        id: 'testing-locations',
        source: 'testing-locations',
        type: 'symbol',
        layout: {
          'icon-image': 'location-marker',
          'icon-size': .25,
          'icon-anchor': 'bottom'
        }
      });
    });

    this.locations.forEach((location) => {
      this.map.addSource(`coverage-${location.id}`, {
        type: 'geojson',
        data: `assets/iso-${location.id}.geojson`
      });
    });
    this.locations.forEach((location) => {
      this.map.addLayer({
        id: `coverage-${location.id}`,
        source: `coverage-${location.id}`,
        type: 'fill',
        paint: {
          'fill-color': ['get', ['to-string', ['get', 'time']], ['literal', this.settings.colors]],
          'fill-opacity': 0.5
        },
        layout: {
          visibility: 'none'
        }
      });
    });

    this.map.setLayoutProperty('coverage-1', 'visibility', 'visible');
  }
}
